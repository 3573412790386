import { observer } from 'mobx-react-lite'
import { Model } from './Model'
import ton from './assets/ton.svg'
import * as Contract from './contract/Stake'
import { ReferralAddress, UnstakeAmount } from './contract/wrappers/tact_Stakee'
import { Address, toNano } from '@ton/core'
import { useTonClient } from './contract/useTonClient'
import { useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { useEffect, useState } from 'react'
import question from './assets/question.svg'
import questionDark from './assets/question-dark.svg'

const referralAddress: string = import.meta.env.VITE_REFERRAL_ADDRESS
interface Props {
    model: Model
}

const StakeUnstake = observer(({ model }: Props) => {
    const { sendMessage, getUserInfo } = Contract.stakeTon()
    const [balance, setBalance] = useState('0.00')
    const [amountError, setErrorAmount] = useState(null)
    const [userData, setUserData] = useState<any>()
    const [contractBalance, setContractBalance] = useState('0.00')

    const [refAddress] = useState(getReferralFromUrl(document.URL) || referralAddress)

    const [tonConnectUI] = useTonConnectUI()
    const wallet = useTonWallet()
    const [isWalletConnected, setIswalletConnected] = useState(false)

    const client = useTonClient()

    const address = useTonAddress()

    const setMaxAmount = async () => {
        try {
            if (model.isStakeTabActive) {
                const addressss = Address.parse(address)
                const balance = await client.getBalance(addressss)
                model.setAmount((Number(balance) / 1e9 - 0.1).toFixed(8))
                setErrorAmount(null)
            } else {
                const addressss = Address.parse(address)
                const result = await getUserInfo(addressss)
                model.setAmount((Number(result.totalStaked) / 1e9).toFixed(8))
                setErrorAmount(null)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBalanceOfWallet = async () => {
        try {
            const addressss = Address.parse(address)
            const balance = await client.getBalance(addressss)
            setBalance((Number(balance) / 1e9).toFixed(4))
        } catch (error) {
            console.log(error)
        }
    }

    function getReferralFromUrl(url: string) {
        // Create a new URL object
        const parsedUrl = new URL(url)

        // Get the search parameters from the URL
        const searchParams = new URLSearchParams(parsedUrl.search)

        // Get the 'refferal' parameter
        const referral = searchParams.get('refferal')

        return referral
    }

    const getUserData = async () => {
        try {
            const addressss = Address.parse(address)
            const result = await getUserInfo(addressss)
            setUserData(result)
            return result
        } catch (error) {
            console.log(error)
        }
    }

    const getContractBalance = async () => {
        try {
            const balance = await client.getBalance(Address.parse(import.meta.env.VITE_CONTRACT_ADDRESS))
            setContractBalance((Number(balance) / 1e9).toString())
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setIswalletConnected(wallet ? true : false)
    }, [wallet])

    useEffect(() => {
        if (client && address) {
            getBalanceOfWallet()
            getContractBalance()
        }
    }, [client, address, model.isStakeTabActive])

    useEffect(() => {
        if (!model.isStakeTabActive && client && address) {
            getUserData()
        }
        setErrorAmount('')
    }, [client, address, model.isStakeTabActive])

    return (
        <div className='font-body mx-auto w-full max-w-screen-lg text-[#7836B5] dark:text-dark-50'>
            <p className='pt-4 text-center text-3xl font-bold'></p>
            <p className='my-8 text-center'>Stake TON and receive 60 % APY</p>

            <div className='dark:bg-tabbar mx-auto my-8 w-max rounded-full bg-milky p-0.5 dark:bg-[#7836b542] dark:text-white'>
                <ul
                    className={
                        'tab-bar relative flex select-none flex-nowrap' +
                        (model.isStakeTabActive ? ' stake' : ' unstake')
                    }
                >
                    <li
                        className='z-[1] m-1 inline-block w-36 cursor-pointer rounded-full py-1 text-center'
                        onClick={() => {
                            model.setActiveTab('stake')
                        }}
                    >
                        Stake
                    </li>
                    <li
                        className='z-[1] m-1 inline-block w-36 cursor-pointer rounded-full py-1 text-center'
                        onClick={() => {
                            model.setActiveTab('unstake')
                        }}
                    >
                        Unstake
                    </li>
                </ul>
            </div>

            <div
                className={
                    'h-8 transition-all duration-700 motion-reduce:transition-none' +
                    (isWalletConnected ? ' max-h-0' : ' max-h-8')
                }
            ></div>

            <div className='mx-auto mb-12 max-w-lg'>
                <div
                    className={
                        'overflow-hidden transition-all duration-700 motion-reduce:transition-none' +
                        (isWalletConnected ? ' max-h-[20rem]' : ' max-h-0')
                    }
                >
                    <div className='mx-4 rounded-t-2xl bg-[#7836B5] px-8 pb-12 pt-4 text-sm text-white dark:bg-[#7836b542] dark:text-dark-50'>
                        <div className='flex flex-row flex-wrap'>
                            <p className='font-light'>TON balance</p>
                            <p className='ml-auto font-medium'>{balance}</p>
                        </div>
                    </div>
                </div>

                <div className='mx-4 -mt-8 rounded-2xl bg-white p-8 shadow-sm dark:bg-[#292626]'>
                    <p>{model.isStakeTabActive ? 'Stake' : 'Unstake'}</p>
                    <div className='mb-8 mt-4'>
                        <label>
                            <div
                                className={
                                    'flex flex-row rounded-lg border border-milky p-4 focus-within:border-[#7836B5] dark:border-dark-900 dark:bg-dark-900 ' +
                                    (model.isAmountValid
                                        ? ''
                                        : ' border-[#7836B5] focus-within:border-[#7836B5] dark:border-[#7836B5] dark:focus-within:border-[#7836B5]')
                                }
                            >
                                <img src={ton} className={'w-7' + (model.isStakeTabActive ? '' : ' hidden')} />
                                <img src={ton} className={'w-7' + (model.isStakeTabActive ? ' hidden' : '')} />
                                <input
                                    type='text'
                                    inputMode='decimal'
                                    placeholder=' Amount'
                                    size={1}
                                    className={
                                        'h-full w-full flex-1 px-3 text-lg focus:outline-none dark:bg-dark-900 dark:text-dark-50' +
                                        (model.isAmountValid ? '' : ' text-[#7836B5] dark:text-[#7836B5]')
                                    }
                                    value={model.amount}
                                    onInput={(e) => {
                                        const target = e.target as HTMLInputElement
                                        if (!isNaN(Number(target.value)) && target.value.trim() !== '') {
                                            model.setAmount(target.value)
                                            setErrorAmount(null)
                                        } else {
                                            model.setAmount('')
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && model.isButtonEnabled) {
                                            const button = document.querySelector<HTMLInputElement>('#submit')
                                            if (button != null) {
                                                button.click()
                                                const target = e.target as HTMLInputElement
                                                target.blur()
                                            }
                                        }
                                    }}
                                />
                                <button
                                    className={
                                        'rounded-lg bg-milky px-3 text-xs hover:bg-gray-200 focus:outline-none active:bg-gray-300 dark:text-dark-600' +
                                        (model.isAmountValid
                                            ? ''
                                            : ' bg-[#7836B5] text-white hover:!bg-[#7836B5]  active:!bg-dark-600 dark:hover:text-dark-50')
                                    }
                                    onClick={setMaxAmount}
                                >
                                    Max
                                </button>
                                {/* )} */}
                            </div>
                        </label>
                        {amountError && <p className='mt-2 text-[#e02e2e]'>{amountError}</p>}
                    </div>
                    {/* <div
                        className={
                            'flex flex-row gap-4 overflow-hidden transition-all motion-reduce:transition-none' +
                            (model.isStakeTabActive ? ' max-h-0 pb-0' : ' max-h-32 pb-8')
                        }
                    ></div> */}
                    {model.isStakeTabActive ? (
                        isWalletConnected ? (
                            <button
                                id='submit'
                                className='h-14 w-full rounded-2xl bg-[#7836B5] text-lg font-medium text-white disabled:opacity-80'
                                onClick={async (e) => {
                                    try {
                                        if (!model.amount) return setErrorAmount('amount is required.')
                                        if (Number(model.amount) == 0)
                                            return setErrorAmount('Amount must be greater than zero')

                                        await getUserInfo(Address.parse(refAddress))
                                        const data = (): ReferralAddress => {
                                            console.log(refAddress, 'test ref address')

                                            return {
                                                $$type: 'ReferralAddress',
                                                referral: Address.parse(refAddress),
                                                amount: toNano(model.amount),
                                            }
                                        }
                                        await sendMessage(data(), (Number(model.amount) + 0.015).toFixed(9))
                                        model.setAmount('')
                                    } catch (error) {
                                        const messageCode = error?.message?.split(':')
                                        if (messageCode && messageCode[1]) {
                                            model.setErrorMessage('Invalid refferal', 1000)
                                        }
                                        console.log(error)
                                    }
                                }}
                            >
                                Stake
                            </button>
                        ) : (
                            <button
                                className='mx-auto block h-14 w-full rounded-2xl bg-[#7836B5] text-lg font-medium text-white dark:text-white'
                                onClick={(e) => {
                                    tonConnectUI.openModal()
                                }}
                            >
                                {'Connect Wallet'}
                            </button>
                        )
                    ) : isWalletConnected ? (
                        <button
                            id='submit'
                            className='h-14 w-full rounded-2xl bg-[#7836B5] text-lg font-medium text-white disabled:opacity-80'
                            onClick={async (e) => {
                                if (!model.amount) return setErrorAmount('amount is required.')
                                if (Number(model.amount) == 0) return setErrorAmount('Amount must be greater than zero')
                                let amount: number = Number(model.amount)
                                let amount1: number = Number(userData.totalStaked) / 1e9

                                if (userData && amount > amount1) {
                                    return setErrorAmount(
                                        'The unstake amount should not be greater than the total staked amount.',
                                    )
                                }
                                if (Number(contractBalance) < Number(model.amount)) {
                                    return model.setErrorMessage('insufficient funds on contract.', 1000)
                                }
                                const data = (): UnstakeAmount => {
                                    return {
                                        $$type: 'UnstakeAmount',
                                        amount: toNano(model.amount),
                                    }
                                }
                                await sendMessage(data(), (0.015).toFixed(9))
                                model.setAmount('')
                            }}
                        >
                            Unstake
                        </button>
                    ) : (
                        <button
                            className='mx-auto block h-14 w-full rounded-2xl bg-[#7836B5] text-lg font-medium text-white dark:text-white'
                            onClick={(e) => {
                                tonConnectUI.openModal()
                            }}
                        >
                            {'Connect Wallet'}
                        </button>
                    )}
                    <div className='mt-12 text-sm font-medium'>
                        <div className='relative my-4 flex flex-row flex-wrap'>
                            <p>Transaction cost</p>
                            <img src={question} className='peer ml-1 w-4 dark:hidden' />
                            <img src={questionDark} className='peer ml-1 hidden w-4 dark:block' />
                            <p className='absolute left-1/3 top-6 z-10 hidden -translate-x-1/4 rounded-lg bg-[#484646] p-4 text-xs font-normal text-white shadow-xl peer-hover:block'>
                                This fee is an average, but to ensure all cases are covered, we initially send extra
                                gas, which is later refunded to your wallet.
                            </p>
                            <p className='ml-auto'>{0.01}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default StakeUnstake
