import { Address, toNano } from '@ton/core'

import { Stakee } from './wrappers/tact_Stakee'
import { useAsyncInitialize } from './useAsyncInitialize'
import { useTonClient } from './useTonClient'
import useTonConnect from './useTonConnect'

function stakeTon() {
    const contractAddress = `${import.meta.env.VITE_CONTRACT_ADDRESS}`
    const client = useTonClient()
    const { sender, address } = useTonConnect()

    let stakeContract = useAsyncInitialize(async () => {
        if (!client) return
        const contract = new Stakee(Address.parse(contractAddress))

        return client.open(contract)
    }, [client])

    return {
        address: stakeContract?.address.toString(),
        sendMessage: async (data, value) => {
            if (stakeContract) {
                return await stakeContract.send(sender, { value: toNano(value) }, data)
            } else {
                return () => {}
            }
        },
        sendValue: async (value) => {
            if (stakeContract) {
                return await sender.send({
                    to: contractAddress,
                    value: toNano(value),
                })
            } else {
                return () => {}
            }
        },
        getUserInfo: async (address1) => {
            return await stakeContract.getGetUserInfo(address1)
        },
        getGetApy: async () => {
            return await stakeContract.getGetApy()
        },
        getGetTimeStep: async () => {
            return await stakeContract.getGetTimeStep()
        },
        getGetTotalStaked: async () => {
            return await stakeContract.getGetTotalStaked()
        }
       
    }
}

export { stakeTon }
